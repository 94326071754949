import { GlobalRouterStore } from './stores/RouterStore';
import GlobalStore from './stores/GlobalStore/GlobalStore';
import CustomersStore from './stores/CustomersStore/CustomersStore';
/* 
    - This is where the stores are instantiated and given their dependencies.
    - The keys here are referenced in each component's inject statements.
    - Remember that every new instance of a store class will have unique states, so it is best to instantiate them once at this level.
*/

export default {
    routerStore: new GlobalRouterStore(),
    customersStore: new CustomersStore(),
    globalStore: new GlobalStore(),
};