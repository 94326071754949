import React, { Component } from 'react';
import { Popup, VerticalMenu, Loader, Input } from '@scuf/common';
import { inject, observer } from 'mobx-react';
import './Customers.scss';
import ICustomersProps from '../Customers/ICustomersProps';
import { DataTable } from '@scuf/datatable';
import createCustomerIcon from '../../assets/img/create_customer.svg';
import { AddCustomer } from '../AddCustomer/AddCustomer';
import { CustomerActionType } from '../../models/CustomerActionType';
import ICustomer, { ICurrency } from '../../models/ICustomer';
import { ToastNotification } from '../../components/ToastNotification/ToastNotification';
import { Constants } from '../../utils/Constants';

interface ICustomerListState {
  isStatusLoading: boolean;
  isModalOpen: boolean;
  customerInfo: ICustomer;
  // tslint:disable-next-line:no-any
  data: any[];
}

@observer
export class Customers extends Component<ICustomersProps, ICustomerListState> {
  private selectedCustomerId: string = '';

  constructor(props: ICustomersProps) {
    super(props);
    this.state = {
      customerInfo: this.getInitCustomerObject,
      data: [],
      isStatusLoading: false,
      isModalOpen: false
    };
    this.actionRenderer = this.actionRenderer.bind(this);
    this.actionEdit = this.actionEdit.bind(this);
  }

  componentDidMount() {
    this.props.customersStore!.getCustomerList();
  }

  get getInitCustomerObject(): ICustomer {
    return {
      addressLine1: '',
      addressLine2: '',
      customerLogo: undefined,
      customerName: '',
      offerings: [],
      customerId: '',
      currency: {} as ICurrency
    };
  }

  openModal() {
    this.setState({
      customerInfo: this.getInitCustomerObject,
      isModalOpen: true
    });
    this.props.customersStore!.showModal('add');
  }

  // tslint:disable-next-line:no-any
  appsPackageRenderer(cellData: any) {
    if (cellData && cellData.value != null) {
      cellData.value.map((data: any) => {
        let offeringName = data.OfferingName.toString().toLowerCase();
        if (offeringName.includes('(') && offeringName.includes(')')) {
          let regex = /\((.*)\)/i;
          let output = regex.exec(data.OfferingName);
          data.OfferingName = output![1];
        }
      });
    }

    return cellData.value != null ? (
      <div>
        {cellData.value.map((data: any, index: number) => {
          return (
            <span key={index} className="offering-badge">
              {data.OfferingName.toString().toUpperCase()}
            </span>
          );
        })}
      </div>
    ) : (
      <div />
    );
  }

  actionRenderer() {
    return (
      <Popup
        hideOnScroll={true}
        element={
          <span>
            <a className="action-button">ACTION</a>
          </span>
        }
        on="click"
      >
        <VerticalMenu>
          <VerticalMenu className="no-padding">
            <VerticalMenu.Item
              iconRoot="building"
              onClick={() => {
                this.props.customersStore!.getCustomerLogo(this.selectedCustomerId);
                this.props.customersStore!.setLoaderText(Constants.LOADER_LOADING);
                this.setState({ isModalOpen: true });
                this.props.customersStore!.showModal('edit');
              }}
            >
              Edit
            </VerticalMenu.Item>
          </VerticalMenu>
        </VerticalMenu>
      </Popup>
    );
  }

  actionEdit(data: any) {
    this.selectedCustomerId = data.rowData.customerId;
    if (data.name === 'Name') {
      var customerName = data.rowData.customerName;
      window.parent.postMessage({ customerId: this.selectedCustomerId, customerName: customerName }, '*');
    }

    if (data.name === 'Actions') {
      this.setState({
        customerInfo: data.rowData
      });
    }
  }

  nameRenderer(cellData: any) {
    return (
      <a
        className="action-button"
        onClick={() => {
          window.parent.postMessage(
            {
              customerId: cellData.rowData.customerId,
              customerName: cellData.rowData.customerName
            },
            '*'
          );
        }}
      >
        {cellData.value}
      </a>
    );
  }

  handleSearchItem(value: string) {
    let currentList: ICustomer[] = [];
    if (value && value.trim() !== '') {
      currentList = this.props.customersStore!.customerListClone;
      this.props.customersStore!.customerList = currentList.filter(item =>
        item.customerName!.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      this.props.customersStore!.customerList = this.props.customersStore!.customerListClone;
    }
  }

  render() {
    const props = this.props.customersStore!;
    return (
      <div className={this.getOverflowClass()}>
        <div className="customers-page-header">
          <Loader
            text={Constants.LOADER_LOADING}
            loading={this.props.customersStore!.getLoading}
            overlayOpacity={0.8}
          >
            <div className="customers-page-title">
              <div className="search-box-wrap">
                <h2>Customers</h2>
                <Input
                  className="input-search"
                  placeholder="Search"
                  search={true}
                  onChange={value => this.handleSearchItem(value)}
                />
              </div>
              <div className="table-head">
                {this.props.customersStore!.showCreateCustomerButton && (
                  <div className="add-cutomer-btn">
                    <img
                      className="cursor-pointer"
                      src={createCustomerIcon}
                      onClick={() => {
                        this.openModal();
                      }}
                    />
                    <span
                      className="add-customer"
                      onClick={() => {
                        this.openModal();
                      }}
                    >
                      {' '}
                      Create Customer{' '}
                    </span>
                  </div>
                )}
                <span className="customer-count">{this.renderCustomerCount()}</span>
              </div>
              <DataTable
                columnResizeMode="expand"
                resizableColumns={true}
                data={this.props.customersStore!.customerList}
                reorderableColumns={true}
                searchPlaceholder="Search"
                onCellClick={data => this.actionEdit(data)}
              >
                <DataTable.Column field="customerName" header="Name" renderer={this.nameRenderer} />
                <DataTable.Column
                  field="offerings"
                  renderer={this.appsPackageRenderer}
                  header="Product"
                />
                {this.props.customersStore!.showCreateCustomerButton ? (
                  <DataTable.Column
                    field="actions"
                    renderer={this.actionRenderer}
                    header="Actions"
                  />
                ) : (
                  <div />
                )}
              </DataTable>
            </div>
          </Loader>
        </div>

        {this.state.isModalOpen && (
          <AddCustomer
            customerInfo={this.state.customerInfo}
            action={
              this.props.customersStore!.addDialogOpened
                ? CustomerActionType.Create
                : CustomerActionType.Edit
            }
            offerings={this.props.customersStore!.offeringAndPackageData}
          />
        )}
        {this.populateToastNotification()}
      </div>
    );
  }

  showNotification(title: string, severity: string, detailMessage: string) {
    this.props.customersStore!.notification!.notify(title, severity, detailMessage);
  }

  private populateToastNotification() {
    return <ToastNotification ref={child => (this.props.customersStore!.notification = child)} />;
  }

  private renderCustomerCount() {
    const countString =
      this.props.customersStore!.customerListClone.length ===
      this.props.customersStore!.customerList.length
        ? 'Total'
        : 'Customers Found';
    const count =
      this.props &&
      this.props.customersStore &&
      this.props.customersStore.customerList &&
      this.props.customersStore.customerList.length
        ? this.props.customersStore!.customerList.length
        : 0;
    return count + ' ' + countString;
  }

  private getOverflowClass() {
    let classname = 'customers-container';
    return this.props.customersStore!.getLoading ? classname + '-hidden' : classname;
  }
}

export default inject('customersStore')(Customers);
