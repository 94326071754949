import Customers from './pages/Customers/Customers';
import NotFoundPage from './pages/NotFound/NotFound';
import { constants } from 'router5';
/* 
    - Routes.ts is the master router config file. Each item defines a route by its name and the parameters it accepts.
    - The component bound to the route will be loaded into the base route view component.
*/
export default [
    {
        name: 'Customers',
        path: '/',
        component: Customers
    },
    {
        name: constants.UNKNOWN_ROUTE,
        path: '/not-found',
        component: NotFoundPage
    }
];