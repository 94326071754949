import React, { SFC } from 'react';
import { routeNode, RouteView } from 'react-mobx-router5';
import routes from '../../routes';
import IBaseRouteViewProps from './IBaseRouteViewProps';

const routeNodeName = '';
const BaseRouteView: SFC<IBaseRouteViewProps> = props => {  
  return <RouteView routes={routes} route={props.route} routeNodeName={routeNodeName} routeProps={props} />;
};

export { BaseRouteView };
export default routeNode(routeNodeName)(BaseRouteView);