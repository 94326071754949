import * as LDClient from "launchdarkly-js-client-sdk";
import { observable, action, computed } from 'mobx';
import { Constants } from '../Constants';

// NOTE:
// - flags are mobx observable either from the flags or your own computed
// property for convenience
// - you can use flags in stores or other non-React.Component locations
// (functions/classes) but the value can not be "reactive" outside a Component.

class FeatureFlags {
  @observable public flags: LDClient.LDFlagSet = {};

  private ldClient?: LDClient.LDClient = undefined;
  private userId?: string = undefined;

  @action public async init(userId?: string) {
    if (this.userId === userId && !!this.ldClient) {
      return;
    }

    try {
      this.ldClient = LDClient.initialize(window.ENV?.LAUNCHDARKLY_CLIENT_ID, {
        key: userId || ''
      });
    } catch (err) {
      this.ldClient = LDClient.initialize(window.ENV?.LAUNCHDARKLY_CLIENT_ID, {
        key: userId || ''
      });
    }

    this.userId = userId;

    const updateFlags = () => {
      this.flags = (this.ldClient && this.ldClient.allFlags()) || {};
    };

    if (this.ldClient) {
      this.ldClient.on('ready', updateFlags, this);
      this.ldClient.on('change', updateFlags, this);
    }
  }

  public get portfolioCurrencyConfiguration() {
    return this.flags[Constants.CURRENCY_FEATURE_FLAG];
  }

  public get isCustomerCurrencyEnabled() {
    return this.flags[Constants.CUSTOMER_CURRENCY_EDITABLE];
  }

  public get isDataCenterSelectionEnabled() {
    return this.flags[Constants.CUSTOMER_DATACENTER_FLAG];
  }
}

export default new FeatureFlags();
