import { observable } from 'mobx';

export default interface IEnvVariables {
    BASE_API_GATEWAY_URL: string;
    OCP_APIM_SUBSCRIPTION_KEY: string;
    LAUNCHDARKLY_CLIENT_ID: string;
}
declare global {
    interface Window {
        ENV: IEnvVariables;
    }
}
export default class GlobalStore {

    @observable
    public envVariables = window.ENV;

} 