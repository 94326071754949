import { observer, inject } from 'mobx-react';
import React, { Component } from 'react';
import ICustomer, { IOffering, ICustomerOffering, ICurrency, ICustomerDataCenter } from '../../models/ICustomer';
import { CustomerActionType } from '../../models/CustomerActionType';
import {
  Modal,
  Button,
  Tab,
  Input,
  Grid,
  FileDrop,
  Checkbox,
  List,
  Loader,
  Select,
  Notification
} from '@scuf/common';
import { InputLabel } from '../../components/InputLabel/InputLabel';
import CustomersStore from '../../stores/CustomersStore/CustomersStore';
import { Constants } from '../../utils/Constants';
import featureFlags from '../../utils/FeatureFlags/FeatureFlags';
import './AddCustomer.scss';

interface IAddCustomerProps {
  customersStore?: CustomersStore;
  customerInfo?: ICustomer;
  action: CustomerActionType;
  offerings: IOffering[];
}

enum AddCustomerWizardSteps {
  Profile = 0,
  Offerings = 1
}

interface IAddCustomerState {
  currentWizardStep: AddCustomerWizardSteps;
  isProfileInfoValid: boolean;
  isOfferningInfoValid: boolean;
  customerId?: string;
  customerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  prevSelectedcurrencyValue?: string;
  isCurrencyModalOpen?: boolean;
  offeringList?: string;
  customerLogo?: any;
  offerings?: IOffering[];
  selectedCurrency?: ICurrency;
  selectedDataCenter?: ICustomerDataCenter;
  modalTitle?: string;
  modalSubtitle?: string;
  modalNotificationTitle?: string;
  modalNotificationBody?: string;
  isErrorModalOn?: boolean;
  isCurrencyFieldSelected: boolean;
  isDataCenterSelectionEnabled: boolean;
}

interface IAddCustomerError {
  customerLogo?: string;
  customerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  dataCenter?: string
}

@inject(Constants.STORE_CUSTOMER)
@observer
export class AddCustomer extends Component<IAddCustomerProps, IAddCustomerState> {
  private fileResetDone: boolean = false;
  private customerNameValidationRequired: boolean = false;
  private addressLine1ValidationRequired: boolean = false;
  private addressLine2ValidationRequired: boolean = false;
  private dataCenterValidationRequired: boolean = false;

  constructor(props: IAddCustomerProps) {
    super(props);
    this.initState(props);
    this.isSecondaryButtonDisabled = this.isSecondaryButtonDisabled.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validateCurrencyField = this.validateCurrencyField.bind(this);
  }

  initState(props: IAddCustomerProps) {
    const { offerings } = props;
    const customerInfo: ICustomer = props.customerInfo!;
    const mergedOffering = offerings
      ? this.mergeOfferings(customerInfo ? customerInfo!.offerings! : [], offerings)
      : [];
    const existingDataCenterOption : ICustomerDataCenter = this.setExistingDataCenter(customerInfo ? customerInfo!.dataCenter! : '');
    const isDataCenterSelectionEnabled = this.isDataCenterEnabled(customerInfo ? customerInfo!.dataCenter! : '');
    this.dataCenterValidationRequired = this.isDataCenterFeatureEnabled();
    this.state = {
      currentWizardStep: AddCustomerWizardSteps.Profile,
      isProfileInfoValid: false,
      isOfferningInfoValid: false,
      customerId: customerInfo ? customerInfo.customerId : '',
      customerName: customerInfo ? customerInfo.customerName : '',
      addressLine1: customerInfo ? customerInfo.addressLine1 : '',
      addressLine2: customerInfo ? customerInfo.addressLine2 : '',
      prevSelectedcurrencyValue: customerInfo
        ? customerInfo.currency
          ? customerInfo.currency.CurrencyIRI
          : ''
        : '',
      isCurrencyModalOpen: false,
      customerLogo: customerInfo ? customerInfo.customerLogo : undefined,
      offerings: mergedOffering,
      selectedCurrency: customerInfo ? customerInfo.currency : undefined,
      selectedDataCenter: existingDataCenterOption!,
      modalTitle: '',
      modalSubtitle: '',
      modalNotificationTitle: '',
      modalNotificationBody: '',
      isErrorModalOn: false,
      isCurrencyFieldSelected: false,
      isDataCenterSelectionEnabled
    };
  }

  componentWillReceiveProps(nextProps: IAddCustomerProps) {
    this.initState(nextProps);
  }

  mergeOfferings(
    enabledOffering: ICustomerOffering[],
    availableOfferings: IOffering[]
  ): IOffering[] {
    if (enabledOffering && enabledOffering.length === 0) {
      return availableOfferings;
    }

    if (!availableOfferings || availableOfferings.length === 0) {
      return [];
    }

    const mergedOffering = availableOfferings;
    if (enabledOffering) {
      enabledOffering.forEach(enabledOffer => {
        const offerIndex = mergedOffering.findIndex(
          offer => offer.Name.toLocaleLowerCase() === enabledOffer.OfferingId.toLocaleLowerCase()
        );
        mergedOffering[offerIndex].IsAssigned = true;
        mergedOffering[offerIndex].isEditable = true;
      });
    }
    return mergedOffering;
  }

  render() {
    const validation: IAddCustomerError = this.validate();
    let loader = (
      <Loader
        text={this.props.customersStore!.getLoaderText}
        loading={this.props.customersStore!.getLoading}
        overlayOpacity={1.0}
        minHeight={100}
      />
    );
    const showDataCenterSelection = this.isDataCenterFeatureEnabled() ? {display: 'block'} : {display: 'none'}
    let modal = (
      <Modal
        className="no-padding no-overflow"
        size="fullscreen"
        open={this.props.customersStore!.isModalOpen}
        closeIcon={false}
      >
        <Modal.Header className="modal-header">
          <div className="new-customer">
            {' '}
            {this.props.action === CustomerActionType.Create
              ? Constants.CUSTOMER_ADD_HEADING
              : Constants.CUSTOMER_EDIT_HEADING}
          </div>
        </Modal.Header>
        <Modal.Content className="modal-body">
          <Tab
            className="common-body-footer-padding modal-hr"
            activeIndex={this.state.currentWizardStep}
            onTabChange={activeIndex => this.onTabChange(activeIndex)}
          >
            <Tab.Pane
              className="common-body-footer-padding"
              title={Constants.CUSTOMER_ADD_EDIT_TAB1_TITLE}
            >
              <div className="style-typography-h-1-dark">
                <div className="page-title">{Constants.CUSTOMER_TITLE_OF_PAGE}</div>
              </div>
              <Grid className="no-margin">
                <Grid.Row>
                  <Grid.Column>
                    <div className="wrapper">
                      <div className="group-2">
                        <InputLabel label={Constants.CUSTOMER_NAME_LABEL} indicator={'required'} />
                        <Input
                          placeholder={Constants.CUSTOMER_NAME_LABEL}
                          value={this.state.customerName}
                          onChange={value => {
                            if (value.length > 50) {
                              return;
                            }
                            this.setState({ customerName: value });
                            this.customerNameValidationRequired = true;
                          }}
                          error={validation.customerName}
                        />
                      </div>
                      <div className="group-2">
                        <InputLabel label={Constants.CUSTOMER_ADDRESS1_LABEL} />
                        <Input
                          placeholder={Constants.CUSTOMER_ADDRESS1_LABEL}
                          value={this.state.addressLine1}
                          onChange={value => {
                            this.setState({ addressLine1: value });
                            this.addressLine1ValidationRequired = true;
                          }}
                          error={validation.addressLine1}
                        />
                      </div>
                      <div className="group-2">
                        <InputLabel label={Constants.CUSTOMER_ADDRESS2_LABEL} />
                        <Input
                          placeholder={Constants.CUSTOMER_ADDRESS2_LABEL}
                          value={this.state.addressLine2}
                          onChange={value => {
                            this.setState({ addressLine2: value });
                            this.addressLine2ValidationRequired = true;
                          }}
                          error={validation.addressLine2}
                        />
                      </div>
                    </div>
                    <div className="placeholder" />
                  </Grid.Column>
                </Grid.Row>
                {this.getPortfolioCurrencyConfig() && (
                  <Grid.Row>
                    <Grid.Column>
                      <div className="wrapper">
                        <div className="group-2">
                          <InputLabel label={Constants.CUSTOMER_CURRENCY_LABEL} />
                          <Select
                            className="currency-select"
                            placeholder={Constants.CUSTOMER_CURRENCY_SELECT}
                            options={this.props.customersStore!.currencyOptions}
                            search={true}
                            value={
                              this.state!.selectedCurrency
                                ? this.state!.selectedCurrency!.CurrencyIRI
                                : ''
                            }
                            defaultValue={undefined}
                            onChange={value => this.handleCurrencySelect(value)} 
                            disabled={!(featureFlags.isCustomerCurrencyEnabled) && 
                              (this.state.prevSelectedcurrencyValue ? true : false)}                           
                          />                          
                        </div>
                        <div className="group-2" style={showDataCenterSelection}>
                          <InputLabel label={Constants.CUSTOMER_DATACENTER_LABEL} indicator={'required'} />
                          <Select
                            className="dc-select"
                            placeholder={Constants.CUSTOMER_DATACENTER_SELECT}
                            options={this.props.customersStore!.dataCenter}
                            search={true}
                            value={
                              this.state!.selectedDataCenter ? this.state!.selectedDataCenter!.value
                              : ''
                            }
                            defaultValue={undefined}
                            onChange={value => 
                              this.handleDataCenterSelect(value)
                            }
                            error={validation.dataCenter}
                            disabled={!this.state.isDataCenterSelectionEnabled}
                            />
                            </div>
                      </div>
                      <div className="placeholder" />
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column className="col-distance">
                    <div className="uploadImageClass">
                      <FileDrop
                        multiple={false}
                        maxSize={Constants.VALIDATION_LOGO_SIZE}
                        label={Constants.CUSTOMER_LOGO_LABEL}
                        acceptedTypes={['image/*']}
                        indicator="required"
                        buttonText={Constants.CUSTOMER_BROWSE_IMAGE_BUTTON_TEXT}
                        placeholder={Constants.CUSTOMER_PLACEHOLDER_LOGO}
                        onAccepted={files => this.setState({ customerLogo: files[0] })}
                        onRejected={() => this.setState({ customerLogo: undefined })}
                        onReset={() => {
                          this.setState({ customerLogo: undefined });
                          this.fileResetDone = true;
                        }}
                      />
                      <span className="ui error-message">{validation.customerLogo}</span>
                    </div>
                    <div className="group-2">
                      <InputLabel label={Constants.CUSTOMER_LOGO_PREVIEW_LABEL} />
                      <div className="organizationLogoPreviewClass">
                        {this.state.customerLogo && this.state.customerLogo.preview && (
                          <img
                            src={this.state.customerLogo!.preview}
                            height="220px"
                            width="329px"
                          />
                        )}
                        {this.state.customerLogo && !this.state.customerLogo.preview && (
                          <img
                            src={`data:${this.state.customerLogo!.Type};base64,${
                              this.state.customerLogo!.Image
                            }`}
                            height="220px"
                            width="329px"
                          />
                        )}
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
            <Tab.Pane
              className="common-body-footer-padding"
              disabled={!this.isProfileInfoValid(validation)}
              title={Constants.CUSTOMER_ADD_EDIT_TAB2_TITLE}
            >
              <div className="style-typography-h-1-dark">
                <div className="page-title">{Constants.CUSTOMER_TITLE_SELECT_OFFERING}</div>
              </div>
              {this.getOfferingList(this.state.offerings!)}
            </Tab.Pane>
          </Tab>
          <Modal
            closeOnDimmerClick={false}
            className="currency-error-modal"
            closeIcon={true}
            size={'large'}
            open={this.state!.isCurrencyModalOpen}
            onClose={() => this.setState({ isCurrencyModalOpen: false })}
          >
            <Modal.Header>{this.state!.modalTitle}</Modal.Header>
            <Modal.Content>
              <div className="modal-subtitle">
                <b> {this.state!.modalSubtitle}</b>                
              </div>
              <Notification
                hasIcon={true}
                title={this.state!.modalNotificationTitle}
                severity="important"
              >
                {this.state!.modalNotificationBody}
              </Notification>              
            </Modal.Content>
            <Modal.Footer>
              {this.state!.isErrorModalOn && (
                <Button
                  onClick={() =>
                    this.setState({ isCurrencyModalOpen: false, isErrorModalOn: false })
                  }
                  type="primary"
                  size="small"
                  content="OK"
                />
              )}              
            </Modal.Footer>
          </Modal>
        </Modal.Content>
        <Modal.Footer className="common-body-footer-padding modal-footer">
          <div className="create-customer-buttons">
            <Button
              type="secondary"
              size="small"
              content={Constants.WIZARD_BTN_CANCEL}
              onClick={this.onClose}
            />
            <Button
              type="primary"
              size="small"
              content={
                (this.state.currentWizardStep as AddCustomerWizardSteps) ===
                (AddCustomerWizardSteps.Profile as AddCustomerWizardSteps)
                  ? Constants.WIZARD_BTN_NEXT
                  : Constants.WIZARD_BTN_SAVE
              }
              onClick={() => this.onSecondaryButtonClick(this.state.currentWizardStep, validation)}
              disabled={this.isSecondaryButtonDisabled(this.state.currentWizardStep, validation)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    );

    return this.props.customersStore!.getLoading ? loader : modal;
  }

  private getOfferingList(offerings: IOffering[]) {
    return offerings.length > 0 ? (
      <List>
        {offerings!.map((item: IOffering, index: number) => {
          return (
            <List.Content
              className={this.getListClass(index)}
              key={index}
              header={item.Description!}
            >
              <Checkbox
                className="offering-check-box"
                label={Constants.CUSTOMER_OFFERING_ENABLE_LABEL}
                onChange={() => this.handleOfferingSelect(index)}
                checked={item.IsAssigned}
                disabled={item.isEditable}
              />
            </List.Content>
          );
        })}
      </List>
    ) : (
      <div />
    );
  }

  private onTabChange(selectedStep: AddCustomerWizardSteps) {
    this.setState({ currentWizardStep: selectedStep });
  }

   handleOfferingSelect = (id: number) => {
    const offerings = this.state.offerings!;
    offerings[id].IsAssigned = !this.state.offerings![id].IsAssigned;
    this.setState({ offerings });
  };

  handleCurrencySelect = (selectedCurrencyIRI: string) => {
    const currencyList = this.props.customersStore!.currencyList;
    const selectedCurrency = currencyList.find(
      currency => currency.CurrencyIRI === selectedCurrencyIRI
    );
    this.setState({ selectedCurrency });
  };

  handleDataCenterSelect = (selectedDC: string) => {
    const dcList = this.props.customersStore!.dataCenter;
    const selectedDataCenter = dcList.find(
      dataCenter => dataCenter.value === selectedDC
    )
    this.setState({selectedDataCenter})
  }

  private isProfileInfoValid(validation: IAddCustomerError): boolean {
    return (
      this.state.customerName!.trim().length !== 0 &&
      this.state.customerLogo &&
      !(validation.addressLine1!.trim().length !== 0) &&
      !(validation.addressLine2!.trim().length !== 0) &&
      !(validation.dataCenter!.trim().length !== 0)
    );
  }

   showCurrencyErrorModal(mode: 'error') {
    this.setState({ isCurrencyModalOpen: true });
    if (mode === Constants.CUR_ERROR) {
      this.setState(() => ({
        isErrorModalOn: true,
        modalTitle: Constants.ERROR_MODAL_TITLE,
        modalSubtitle: Constants.ERROR_MODAL_SUBTITLE,
        modalNotificationTitle: Constants.ERROR_MODAL_NTFCN_TITLE,
        modalNotificationBody: Constants.ERROR_MODAL_NTFCN_BODY
      }));
    }    
  }

   validateCurrencyField(): boolean {
    const offering = this.state!.offerings!.find(res => res.Name === 'SPOG')!;
    if (offering !== undefined) {
      const isForgeEnabled = offering.IsAssigned;
      const isSelectedCurrencyEmpty =
        this.state!.selectedCurrency! !== null || undefined
          ? Object.keys(this.state!.selectedCurrency!).length === 0 || undefined
            ? true
            : false
          : true;
      if (isForgeEnabled && isSelectedCurrencyEmpty) {
        this.showCurrencyErrorModal(Constants.CUR_ERROR);
        return false;
      }

      if (!isSelectedCurrencyEmpty && !this.state!.prevSelectedcurrencyValue) {
        this.setState({ isCurrencyFieldSelected: true });
        this.saveCustomer();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private isOfferingInfoValid(validation: IAddCustomerError): boolean {
    return true;
  }

  private validate(): IAddCustomerError {
    let error: IAddCustomerError = {
      customerName: undefined,
      addressLine1: undefined,
      addressLine2: undefined,
      dataCenter: undefined
    };

    if (this.state) {
      error = {
        customerLogo:
          this.state.customerLogo || !this.fileResetDone
            ? ''
            : Constants.VALIDATION_CUSTOMER_LOGO_REQUIRED,
        customerName: !this.customerNameValidationRequired ? '' : this.getErrorForCustomerName(),
        addressLine1: !this.addressLine1ValidationRequired ? '' : this.getErrorForAddressLine1(),
        addressLine2: !this.addressLine2ValidationRequired ? '' : this.getErrorForAddressLine2(),
        dataCenter:   this.dataCenterValidationRequired ? this.getErrorForDataCenter(): '' 
      };
      return error;
    }
    return error;
  }

  private getErrorForCustomerName(): string {
    if (this.state.customerName && this.state.customerName!.length > 0) {
      const validateCustomerName = /^[\w#]+[\w\s\.,\-'#&|#&]*$/;
      const isCustomerNameValid = validateCustomerName.test(this.state.customerName!.trim());
      if (!isCustomerNameValid) {
        return Constants.VALIDATION_CUSTOMER_NAME_INVALID;
      }
      const custName = this.state.customerName;
      let isDuplicate: boolean = false;
      if (this.props.customersStore) {
        if (this.props.action === CustomerActionType.Create) {
          isDuplicate = this.isCustomerExists(custName);
        }
        if (this.props.action === CustomerActionType.Edit) {
          const isSameItem: Boolean =
            this.props.customersStore.customerList!.findIndex(
              res =>
                res.customerName!.toLocaleLowerCase() === custName.toLocaleLowerCase() &&
                res.customerId === this.state.customerId
            ) != -1;
          if (!isSameItem) {
            isDuplicate = this.isCustomerExists(custName);
          }
        }
        if (isDuplicate) {
          return Constants.VALIDATION_CUSTOMER_NAME_DUPLICATE;
        }
      }
    } else {
      return Constants.VALIDATION_CUSTOMER_NAME_REQUIRED;
    }
    return '';
  }

  private getErrorForAddressLine1(): string {
    if (this.state.addressLine1 && this.state.addressLine1!.length > 0) {
      const addressPattern = /^[\w#]+[\w&#45;\s\.'#&|#&]*$/;
      const isAddressLineValid = addressPattern.test(this.state.addressLine1!.trim());
      if (!isAddressLineValid) {
        return Constants.VALIDATION_ADDRESS_LINE1_INVALID;
      }
    }
    return '';
  }

  private getErrorForDataCenter() : string {
    if (this.state.selectedDataCenter!.value === '' || this.state.selectedDataCenter!.value === undefined) {
      return Constants.VALIDATION_DATACENTER_REQUIRED;
    }
    return ''
  }

  private getErrorForAddressLine2(): string {
    if (this.state.addressLine2 && this.state.addressLine2!.length > 0) {
      const addressPattern = /^[\w#]+[\w&#45;\s\.'#&|#&]*$/;
      const isAddressLineValid = addressPattern.test(this.state.addressLine2!.trim());
      if (!isAddressLineValid) {
        return Constants.VALIDATION_ADDRESS_LINE2_INVALID;
      }
    }
    return '';
  }

  private isCustomerExists(custName: string) {
    if (this.props.customersStore) {
      return (
        this.props.customersStore.customerList!.findIndex(
          res => res.customerName!.toLocaleLowerCase() === custName.toLocaleLowerCase()
        ) != -1
      );
    }
    return false;
  }

  private isSecondaryButtonDisabled(
    currentWizardStep: AddCustomerWizardSteps,
    validation: IAddCustomerError
  ): boolean {
    switch (currentWizardStep as AddCustomerWizardSteps) {
      case AddCustomerWizardSteps.Profile as AddCustomerWizardSteps:
        return !this.isProfileInfoValid(validation);
      case AddCustomerWizardSteps.Offerings as AddCustomerWizardSteps:
        return !this.isOfferingInfoValid(validation);
      default:
        return false;
    }
  }

  private onClose() {
    this.fileResetDone = false;
    this.customerNameValidationRequired = false;
    this.addressLine1ValidationRequired = false;
    this.addressLine2ValidationRequired = false;
    this.props.customersStore!.dismissModal();
    this.props.customersStore!.dismissModal();
  }

  private onSecondaryButtonClick(
    currentWizardStep: AddCustomerWizardSteps,
    validation: IAddCustomerError
  ): void {
    switch (currentWizardStep as AddCustomerWizardSteps) {
      case AddCustomerWizardSteps.Profile as AddCustomerWizardSteps:
        this.setState({ currentWizardStep: AddCustomerWizardSteps.Offerings });
        break;
      case AddCustomerWizardSteps.Offerings as AddCustomerWizardSteps:
        if (
          this.isProfileInfoValid(validation) &&
          this.isOfferingInfoValid(validation) &&
          (this.getPortfolioCurrencyConfig() ? this.validateCurrencyField() : true)
        ) {
          if (this.getPortfolioCurrencyConfig() && !this.state!.isCurrencyFieldSelected) {
            this.validateCurrencyField();
          }
          this.saveCustomer();
        }
        break;
      default:
        break;
    }
  }

   saveCustomer = () => {
    const formData = new FormData();
    formData.append('customerName', this.state.customerName!);
    formData.append('AddressLine1', this.state.addressLine1!);
    formData.append('AddressLine2', this.state.addressLine2!);
    formData.append('OfferingList', JSON.stringify(this.state.offerings));
    if (this.getPortfolioCurrencyConfig()) {
      const isSelectedCurrencyEmpty =
        this.state!.selectedCurrency! !== null || undefined
          ? Object.keys(this.state!.selectedCurrency!).length === 0 || undefined
            ? true
            : false
          : true;
      if (isSelectedCurrencyEmpty) {
        formData.append('currency', JSON.stringify(null));
      } else {
        formData.append('currency', JSON.stringify(this.state.selectedCurrency));
      }
    }
    if (this.state.isDataCenterSelectionEnabled 
      && this.state.selectedDataCenter! !== null 
      && this.state.selectedDataCenter !== undefined
      && this.state.selectedDataCenter.value !== undefined) {
      formData.append('DataCenter', this.state.selectedDataCenter!.value)
    } else {
      formData.append('DataCenter', 'US') // This is to prevent the data center from going as a string 'undefined'
    }
    this.props.customersStore!.setLoaderText(Constants.LOADER_SAVING);
    if (this.state.customerLogo && this.state.customerLogo.preview) {
      formData.append('CustomerLogo', this.state.customerLogo!);
      this.editOrCreateCustomer(formData, this);
    } else {
      const url = `data:${this.state.customerLogo!.Type};base64,${this.state.customerLogo!.Image}`;

      const instance = this;
      this.urltoFile(url, 'customerDetailLogo.file', this.state.customerLogo!.Type).then(function(
        file: File
      ) {
        formData.append('CustomerLogo', file);
        instance.editOrCreateCustomer(formData, instance);
      });
    }
  };

  private editOrCreateCustomer(formData: FormData, instance: AddCustomer) {
    instance.props.customersStore!.setLoaderText(Constants.LOADER_SAVING);
    if ((instance.props.action as CustomerActionType) === CustomerActionType.Create) {
      instance.props.customersStore!.createCustomer(formData);
      instance.onClose();
    }

    if ((instance.props.action as CustomerActionType) === CustomerActionType.Edit) {
      instance.props.customersStore!.editCustomer(instance.state.customerId!, formData);
      instance.onClose();
    }
  }

  private urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function(res: any) {
        return res.arrayBuffer();
      })
      .then(function(buf: any) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  private getListClass(key: number) {
    let classes = 'offering-header-alignment ';
    key === 0
      ? (classes = classes + 'offering-header-first-item')
      : (classes = classes + 'offering-header-rest-item');
    return classes;
  }

  getPortfolioCurrencyConfig() {
    return (
      featureFlags.portfolioCurrencyConfiguration && featureFlags.portfolioCurrencyConfiguration!.length
    );
  }

  private isDataCenterFeatureEnabled(): boolean {
    return featureFlags.isDataCenterSelectionEnabled
  }

  private isDataCenterEnabled(dataCenter: string): boolean {
    return dataCenter === undefined || dataCenter === '';
  }

  private setExistingDataCenter(dataCenter: string): ICustomerDataCenter {
    return dataCenter !== '' ?
      {text: dataCenter!, value: dataCenter!}: {} as ICustomerDataCenter;
  }
}
