import * as React from 'react';
import { IInputLabelProps } from './IInputLabelProps';
import { Badge } from '@scuf/common';

export class InputLabel extends React.Component<IInputLabelProps> {
  static defaultProps: Partial<IInputLabelProps> = {
    label: 'Input Label',
    indicator: undefined,
    color: undefined,
    weight: undefined,
    size: undefined
  };

  constructor(props: IInputLabelProps) {
    super(props);
  }

  render() {
    const { label, indicator, color, weight, size, ...rest } = this.props;

    const optionalType = {
      color: color,
      fontSize: size,
      fontWeight: weight,
      marginRight: 15
    } as React.CSSProperties;

    return (
      <div {...rest} className="ui input-label">
        <span className="input-label-message" style={optionalType}>
          {this.props.label}
        </span>
        {this.props.indicator === 'required' ? (
          <Badge className="badge" color="red" empty={true} />
        ) : null}
        {this.props.indicator === 'optional' ? (
          <span className="input-label-optional">Optional</span>
        ) : null}
      </div>
    );
  }
}
