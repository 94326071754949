import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import BaseRouteView from './components/BaseRouteView/BaseRouteView';

@observer
export default class App extends Component {
  render() {
    return (
      <Fragment>
        <BaseRouteView />
      </Fragment>
    );
  }
}
