import { RouterStore } from 'mobx-router5';
import { Route, Params } from 'router5/create-router';

export interface IDeviceRoute extends Route {
    params: Params;
}
export class GlobalRouterStore extends RouterStore {
    public route!: IDeviceRoute;
}
const routerStore = new GlobalRouterStore();

export default routerStore;